
.myProject {
  .top_content {
    display: flex;
    position: relative;
    .left {
      width: 240px;
      color: #999;
      .v-input {
        position: relative;
        left: -20px;
        top: 16px;
        .v-input__slot {
          box-shadow: none;
          .v-text-field__slot {
            position: relative;
            left: 15px;
            color: #999;
          }
        }
        .v-input__prepend-outer {
          position: relative;
          left: 45px;
          top: 2px;
          z-index: 1;
          .v-icon.v-icon {
            color: #3f81c1 !important;
          }
        }
      }
    }
  }
  .tab_content {
    position: relative;
    margin-left: 16px;
    min-height: calc(100vh - 300px);
    .tab {
      width: 300px;
    }
    .v-card__text,
    .v-card__title {
      padding: 8px 8px 0 8px;
    }
    .v-card__actions {
      padding: 0 8px;
    }
    .menu_list:hover {
      .v-list-item__title {
        color: #3f81c1;
      }
    }
    .tabs_items {
      width: calc(100% - 16px);
      padding-bottom: 80px;
      background: none;
      .folder_card {
        padding-top: 54px;
        .folder-title {
          display: block;
          padding-top: 16px;
          width: 100%;
          text-align: center;
        }
      }
      .card_item {
        cursor: pointer;
        background: #f8f8f8;
        position: relative;
        border-radius: 8px;
        .topRight {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          width: 32px;
          height: 32px;
          background: #ffaf46;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 8px;
          text-align: center;
          line-height: 28px;
        }
        .actions_item {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .text_title {
        line-height: 20px;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .text_content {
        font-size: 12px;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 20px;
      }
      .tabs_items_img {
        width: 136px;
        height: 106px;
        margin: 0 auto;
        .menu_list:hover {
          .v-list-item__title {
            color: #3f81c1;
          }
        }
      }
    }
    .pagination {
      position: absolute;
      right: 0;
      bottom: 20px;
    }
  }
}
